import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './card.css';

const CardGrid  = ({ cards }) => {

  return (
    <div>
      <h1 className="page-heading">DES Documentation</h1>
      <div className="card-grid">
        {cards.map((card, idx) => (
            <Card key={idx}>
              <Card.Header>
                <Card.Title>{card.title}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>{card.text}</Card.Text>
                <Link to={card.link}>
                  <Button variant="primary">{card.title}</Button>
                </Link>
              </Card.Body>
            </Card>     
        ))}
      </div>
    </div>
    
  );
};

export default CardGrid;