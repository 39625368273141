import React from "react";
import "./styles.css";
import { Route, Routes } from 'react-router-dom';
import Main from "./components/main/Main";
import LandingPage from "./components/landing/LandingPage";
export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/landing" element={<LandingPage />} />
    </Routes>
  );
}
