import React from "react";
import './LeftComponent.css';

export default function LeftComponent() {
  return (
    <div className="left-side">
        <div>
            <a href="/.auth/login/cad?post_login_redirect_uri=https://des-portal.digitalforairlines.com/landing">
                <button className="left-button">Airline Users</button>
            </a>
        </div>
    </div>
  );
}
