import React from "react";
import "./Main.css";
import LeftComponent from "../left/LeftComponent";
import RightComponent from "../right/RightComponent";
import CommonHeading from "../common/CommonHeading";
import Footer from "../footer/Footer";


export default function Main() {
  return (
    <div>
        <CommonHeading />
        <LeftComponent />
        <RightComponent />
        <Footer note="© Amadeus, 2023. All rights reserved." />
    </div>
  );
}


