import React from "react";
import "./RightComponent.css"

export default function RightComponent() {
  return (
    <div className="right-side">
        <div>
            <a href="/.auth/login/aad?post_login_redirect_uri=https://des-portal.digitalforairlines.com/landing">
                <button className="right-button">Amadeus Users</button>
            </a>
        </div>
        <img src={require('../../images/amadeus-logo.png')} alt="Logo" className="image-amadeus-logo"/>
    </div>
  );
}
