import React from "react";
import './CommonHeading.css';

export default function CommonHeading() {
  return (
    <div>
        <div className="common-heading">
            <h1>Documentation Portal</h1>
        </div>
        <p className="login-text">Login to continue as an Airline user or Amadeus User</p>   
    </div>
    
  );
}
