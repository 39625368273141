import { useEffect } from "react";
import { useState } from "react";
//import axios from "axios";
import CardGrid from './DynamicTabs';
import jsonData from './groupRuleMapping.json';

export default function  LandingPage(){

     const [cards,setCards] = useState([]);
    //listing the tabs

    useEffect( () => {
        async function getUserInfo() {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            return clientPrincipal;
        }
    
        async function getUserRoleMapping(){
            
            const clientPrincipal = await getUserInfo();
            const currUserRoles = clientPrincipal.userRoles;
            const currProvider = clientPrincipal.identityProvider;
            const portals = Object.keys(jsonData);
            const accessablePortal = [];
            
            for (let fieldName of portals) {
                let accessGroups = jsonData[fieldName];
            
                for(let accessGroup of accessGroups['roles']){
                    let groupMatch = currUserRoles.filter( (group) =>  group === accessGroup)
                    if (groupMatch.length !== 0){
                        accessablePortal.push(fieldName);
                        break;
                    }
                }
            }
            return accessablePortal;
    
        }
    
        getUserRoleMapping().then((accessPortalDetails) => {
            console.log(accessPortalDetails);
            const cards = [];
            for(let accessablePortal of accessPortalDetails){
                let cardInfo = {
                  title: jsonData[accessablePortal]['title'],
                  text: jsonData[accessablePortal]['description'],
                  link: jsonData[accessablePortal]['link']
                }
                cards.push(cardInfo);
                
            }
            console.log(cards);
            setCards(cards);
        });;

    }, []);

    return(
        <div>
            <div>
                <CardGrid cards={cards} />
            </div>
        </div>
    );
}